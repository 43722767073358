import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthUtils } from "src/app/shared/services/auth/auth.utils";
import { UserService } from "../user.service";
import { settings } from "src/app/shared/settings/config";
import { User } from "../../classes/user.types";

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  get accessToken(): string {
    return localStorage.getItem("accessToken") ?? "";
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string, template: string): Observable<any> {
    let setting: settings = new settings();
    return this._httpClient.post(setting.ApiUrl + "Seller/Forgot-Password", {
      Email: email,
      Template: template,
    });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post("api/auth/reset-password", password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in

    if (this._authenticated) {
      return throwError("User is already logged in.");
    }
    let setting: settings = new settings();
    return this._httpClient
      .get(setting.ApiUrl + "seller/login", {
        params: {
          username: credentials.email,
          password: credentials.password,
        },
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.result.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Mapping
          const resultUser: User = {
            id: response.result.seller.id,
            email: response.result.seller.eMail,
            name: response.result.seller.fullName,
          };

          // Store the user on the user service
          this._userService.user = resultUser;

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    let setting: settings = new settings();
    // Renew token
    return this._httpClient
      .get(setting.ApiUrl + "seller/RefreshAccessToken", {
        params: {
          accessToken: this.accessToken,
        },
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.result.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Mapping
          const resultUser: User = {
            id: response.result.seller.id,
            email: response.result.seller.eMail,
            name: response.result.seller.fullName,
          };

          // Store the user on the user service
          this._userService.user = resultUser;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem("accessToken");

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    referrerCode: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    mobilePhone: string;
  }): Observable<any> {
    let setting: settings = new settings();
    return this._httpClient.get(setting.ApiUrl + "seller/register", {
      params: {
        referrerCode: user.referrerCode,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        mobilePhone: user.mobilePhone,
      },
    });
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post("api/auth/unlock-session", credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
