import { Entity } from "src/app/shared/models/Entity";
import { HttpClient } from "@angular/common/http";
import { settings } from "src/app/shared/settings/config";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { EntityValue } from "../models/EntityValue";

@Injectable({
  providedIn: "root",
})
export class EntityService {
  constructor(private http: HttpClient) {}

  GetEntityListByEntitySetID(entitySetID: Number): Observable<any> {
    let setting: settings = new settings();
    return of(
      this.http.get(
        setting.ApiUrl +
          "api/Entity/EntityListBySetID?EntitySetID=" +
          entitySetID
      )
    );
  }

  GetEntityListByEntitySetIDresult(entitySetID: Number): Observable<Entity[]> {
    let setting: settings = new settings();
    return this.http.get<Entity[]>(
      setting.ApiUrl + "api/Entity/EntityListBySetID?EntitySetID=" + entitySetID
    );
  }

  GetEntityById(entityId: Number): Observable<any> {
    let setting: settings = new settings();
    return of(
      this.http.get(
        setting.ApiUrl + "api/Entity/EntityById?entityId=" + entityId
      )
    );
  }

  GetEntityByIdPublic(entityId: Number): Observable<any> {
    let setting: settings = new settings();
    return of(
      this.http.get(
        setting.ApiUrl + "api/Entity/GetEntityByIdPublic?entityId=" + entityId
      )
    );
  }

  GetEntitySetByEntitySetID(entitySetID: Number): Observable<any> {
    let setting: settings = new settings();
    return of(
      this.http.get(
        setting.ApiUrl + "api/Entity/EntitySetById?EntitySetID=" + entitySetID
      )
    );
  }

  GetAttributeValue(entity: Entity, attributeID: Number): string {
    if (entity.entityValues === undefined) return "";
    return entity?.entityValues.find((x) => x.entityAttributeId == attributeID)
      .value;
  }

  GetAttribute(entity: Entity, attributeID: Number): EntityValue {
    return entity?.entityValues.find((x) => x.entityAttributeId == attributeID);
  }
}
