import { environment } from "src/environments/environment";

export class settings {
  constructor() {}
  public get ApiUrl(): string {
    return environment.ApiUrl;
  }

  public get AdminUrl(): string {
    return environment.AdminUrl;
  }
  public get DnsSafeUrl(): string {
    let host = window.location.host;
    if (host == "localhost:4200") {
      host = "localhost";
    }
    return host;
  }
}
