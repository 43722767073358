import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, startWith, delay, switchMap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { settings } from "../settings/config";
import { ProductsEnt } from "../models/ProductEnt";
import { HttpControllerResponse } from "../models/HttpRequestResponse/HttpControllerResponse";
import { environment } from "src/environments/environment";
import { ProductReview } from "../models/ProductReview";

const state = {
  products: JSON.parse(localStorage["products"] || "[]"),
  wishlist: JSON.parse(localStorage["wishlistItems"] || "[]"),
  compare: JSON.parse(localStorage["compareItems"] || "[]"),
  cart: JSON.parse(localStorage["cartItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = {
    name: environment.currencyName,
    currency: environment.currency,
    price: 1,
  }; // Default Currency
  public OpenCart: boolean = false;
  public Products;

  constructor(private http: HttpClient, private toastrService: ToastrService) {}

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  public get productsAPI(): Observable<Product[]> {
    let setting: settings = new settings();
    this.Products = this.http
      .get<Product[]>(setting.ApiUrl + "api/categories/GetAllProducts")
      .pipe(
        map((next: ProductsEnt[]) => {
          var productList: Product[] = [];
          let appSetting: settings = new settings();

          next.forEach((element) => {
            productList.push({
              brand: element.Brand,
              id: element.ID,
              category: element.CategoryID,
              description: element.Description,
              shortDescription: element.ShortDescription,
              title: element.Name,
              titleSEO: element.Ingredient,
              categorySEO: element.ProposeedUse,
              discount: 0,
              deliveryAmount: element.Weight,
              new: true,
              price: element.Price,
              sale: false,
              stock: 500,
              tax: element.TaxPercent,
              tags: [element.Brand, element.CategoryID],
              type: element.CategoryID,
              collection: ["new products"],
              //images: [{ id: 1, alt: "white", src: "assets/images/product/fashion/6.jpg" }],
              images:
                element.ProductsImage !== undefined
                  ? element.ProductsImage.map((item) => {
                      return {
                        id: item.ID,
                        alt: "",
                        src:
                          appSetting.ApiUrl +
                          "productsImage/" +
                          item.FileName +
                          "." +
                          item.Type,
                      };
                    })
                  : [],
              variants:
                element.Variations !== undefined
                  ? element.Variations.map((item) => {
                      return {
                        id: item.ID,
                        sku: "",
                        color: this.FindColor(item),
                        size: this.FindSize(item),
                        gift: this.FindGift(item),
                        number: this.FindNumber(item),
                        scent: this.FindScent(item),
                      };
                    })
                  : [], //{ id: 1, sku: "sku1", size: "s", color: "yellow", image_id: 111 }
            });
          });
          localStorage["products"] = JSON.stringify(productList);
          return productList;
        })
      );
    return this.Products;
  }

  // "productId": 0,
  // "requestedSellerId": 0,
  // "fullName": "string",
  // "phoneNumber": "string",
  // "email": "user@example.com",
  // "questionId": 0,
  // "insertedDate": "2022-10-30T17:04:05.616Z",
  // "isDeleted": true,
  // "modifiedDate": "2022-10-30T17:04:05.616Z",
  // "messageText": "string"
  AddProductQA(
    ProductId: number,
    fullName: string,
    phoneNumber: string,
    email: string,
    messageText: string,
    rating: number
  ): Observable<HttpControllerResponse> {
    let setting: settings = new settings();
    return this.http.post<HttpControllerResponse>(
      setting.ApiUrl + "api/Product/AddProductQA",
      {
        productId: ProductId,
        fullName: fullName,
        phoneNumber: phoneNumber,
        email: email,
        messageText: messageText,
        rating: rating,
        requestedSellerId: 0,
        insertedDate: new Date(),
        isDeleted: true,
        questionId: 0,
        modifiedDate: new Date(),
      }
    );
  }

  GetProductQAs(productId: number): Observable<ProductReview[]> {
    let setting: settings = new settings();
    return this.http
      .get<HttpControllerResponse>(
        setting.ApiUrl + "api/Product/GetAllQAByProductId",
        {
          params: {
            ProductId: productId,
          },
        }
      )
      .pipe(
        map((resp: HttpControllerResponse) => {
          return resp.result.map((item: ProductReview) => item);
        })
      );
  }
  // Product
  private get products(): Observable<Product[]> {
    if (localStorage["products"] === undefined) {
      return this.productsAPI;
    } else {
      this.productsAPI.subscribe();
      return of(JSON.parse(localStorage["products"] || "[]"));
    }
  }

  FindColor(item: ProductsEnt): string {
    let returnValue: string;
    if (item.VariantName !== undefined) {
      item.VariantName.split(" ").forEach((element) => {
        if (element.toLowerCase() == "renk") {
          let index: number = item.VariantName.split(" ").indexOf(element);
          let colorName = item.VariationValue.split(" ")[index];
          returnValue = colorName;
        }
      });
    } else returnValue = "";

    return returnValue;
  }

  FindSize(item: ProductsEnt): string {
    let returnValue: string;
    if (item.VariantName !== undefined) {
      item.VariantName.split(" ").forEach((element) => {
        if (element.toLowerCase() == "beden") {
          let index: number = item.VariantName.split(" ").indexOf(element);
          let Size = item.VariationValue.split(" ")[index];
          returnValue = Size;
        }
      });
    } else returnValue = "";

    return returnValue;
  }

  FindGift(item: ProductsEnt): string {
    let returnValue: string;
    if (item.VariantName !== undefined) {
      item.VariantName.split(" ").forEach((element) => {
        if (element.toLowerCase() == "hediye") {
          let index: number = item.VariantName.split(" ").indexOf(element);
          let Gift = item.VariationValue.split(" ")[index];
          returnValue = Gift;
        }
      });
    } else returnValue = "";

    return returnValue;
  }

  FindNumber(item: ProductsEnt): string {
    let returnValue: string;
    if (item.VariantName !== undefined) {
      item.VariantName.split(" ").forEach((element) => {
        if (element.toLowerCase() == "numara") {
          let index: number = item.VariantName.split(" ").indexOf(element);
          let Number = item.VariationValue.split(" ")[index];
          returnValue = Number;
        }
      });
    } else returnValue = "";

    return returnValue;
  }
  FindScent(item: ProductsEnt): string {
    let returnValue: string;
    if (item.VariantName !== undefined) {
      item.VariantName.split(" ").forEach((element) => {
        if (element.toLowerCase() == "scent") {
          let index: number = item.VariantName.split(" ").indexOf(element);
          let Scent = item.VariationValue.split(" ")[index];
          returnValue = Scent;
        }
      });
    } else returnValue = "";

    return returnValue;
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(
      map((items) => {
        localStorage["products"] = JSON.stringify(items);
        return items.find((item: any) => {
          return item.titleSEO === slug;
        });
      })
    );
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find((item) => item.id === product.id);
    if (!wishlistItem) {
      state.wishlist.push({
        ...product,
      });
    }
    this.toastrService.success("Product has been added in wishlist.");
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true;
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find((item) => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    this.toastrService.success("Product has been added in compare.");
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cart.find(
      (item) =>
        item.id === product.id &&
        item.selectedVariant === product.selectedVariant
    );
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) return false;

    if (cartItem) {
      cartItem.quantity += qty;
    } else {
      state.cart.push({
        ...product,
        quantity: qty,
      });
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(
    product: Product,
    quantity: number
  ): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity;
        const stock = this.calculateStockCounts(state.cart[index], quantity);
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      }
    });
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < qty || stock == 0) {
      this.toastrService.error(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    return true;
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100;
          }
          return (prev + price * curr.quantity) * this.Currency.price;
          //return (prev + price * curr.quantity+curr.deliveryAmount) * this.Currency.price; withdelivery
        }, 0);
      })
    );
  }

  public cartSubTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100;
          }
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      })
    );
  }

  public cartDeliveryAmount(): Observable<number> {
    return this.cartTotalAmount().pipe(
      switchMap((total: number) => {
        if (total >= 600) {
          return of(0);
        } else {
          return of(70);
          //urune gore tutar girilir, suan sabit 70 tl
          // this.cartItems.pipe(
          //   map((products: Product[]) => {
          //     return products.reduce((prev, curr: Product) => {
          //       let price = curr.deliveryAmount;
          //       return (prev + price * curr.quantity) * this.Currency.price;
          //     }, 0);
          //   })
          // );
        }
      })
    );
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(
      map((product) =>
        product.filter((item: Product) => {
          if (!filter.length) return true;
          const Tags = filter.some((prev) => {
            // Match Tags
            if (item.tags) {
              if (item.tags.includes(prev)) {
                return prev;
              }
            }
          });
          return Tags;
        })
      )
    );
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload == undefined) return products;
    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 16
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
