import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { settings } from "src/app/shared/settings/config";
import { Observable, of } from "rxjs";
import { CategoriesEnt } from "src/app/shared/models/Category";
import { map } from "rxjs/operators";
import { nullSafeIsEquivalent } from "@angular/compiler/src/output/output_ast";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  categories(): Observable<any> {
    let setting: settings = new settings();
    return this.http.get(setting.ApiUrl + "api/categories/getcategories");
  }

  GetCategoriesByParentId(parentId: number) {
    this.categories().subscribe((resp) =>
      resp.subscribe((x) => {
        return x.find((e) => e.MainId == parentId);
      })
    );
  }
}
